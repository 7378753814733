import { Files } from "../components/Files";
import { Decisions } from "../components/decisions";
import { Tab } from "../types/case-manager/custom-components";
import { PROCESSES } from "../types/processes";
import { TaskProps } from "../types/taskProps";

export const tabs: Tab<TaskProps>[] = [
  {
    id: "files",
    title: "Filer",
    component: Files,
    hide: (flow) => {
      const flowDefinitionId = flow?.flowDefinitionId ?? "";
      return flowDefinitionId !== PROCESSES.CREDIT_PROCESS;
    }
  },
  {
    id: "decisions",
    title: "Decisions",
    component: Decisions,
    hide: (flow) => {
      const flowDefinitionId = flow?.flowDefinitionId ?? "";
      return flowDefinitionId !== PROCESSES.CREDIT_PROCESS;
    },
    badge: ({ flow }) => {
      const values: any[] = Object.values(flow?.data?.decisions ?? {});
      const flattenedValues = values.reduce((acc, val) => {
        return acc.concat(val);
      }, []);
      return flattenedValues.length > 0 ? flattenedValues.length : undefined;
    }
  }
];
