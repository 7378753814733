import {
  BookOpenText,
  Factory,
  HandCoins,
  Handshake,
  Thermometer,
  User
} from "lucide-react";
import { useFlowContext } from "../../../../../hooks/useFlowContext";
import { TaskProps } from "../../../../../types/taskProps";
import { getOverviewHelper } from "../../../../../utils/overviewData";
import { COLOR, Card } from "../../../../Card";
import { CircleIcon } from "../../../../CircleIcon";
import { Collapsible } from "../../../../Collapsible";
import { DisplayComments } from "../../../../DisplayComments";
import { NoRemarks } from "../../../../NoRemarks";
import {
  Table,
  TableBody,
  TableCell,
  TableCellTitle,
  TableRow
} from "../../../../table";
import { CustomTable } from "../../../../table/CustomTable";
import { RejectionReasons } from "../RejectionReasons";
import { HalfMoonChart } from "./HalfMoonChart";

export function Overview(flow: TaskProps["flow"]) {
  const { t } = useFlowContext();
  const { data } = flow;
  const { country } = data?.applicant;
  if (!country) {
    return (
      <NoRemarks
        label={t("could-not-fetch-data-without-country")}
        isSuccess={false}
      />
    );
  }
  const overviewData = getOverviewHelper(data, country);
  if (!overviewData) {
    return <NoRemarks label={t("missingData")} isSuccess={false} />;
  }
  const comment =
    data?.application?.operatorInput?.comment ||
    data?.application?.appeal?.comment;

  const {
    approvedCredit,
    desiredCredit,
    existingCreditLimit,
    maxApprovedCredit,
    remainingCreditLimit,
    operatorInput,
    dunAndBradstreetMaxRecommendedCredit
  } = overviewData.decisionData;

  return (
    <div className="flex max-w-5xl flex-col gap-10 overflow-auto">
      <DisplayComments comment={comment} />
      <RejectionReasons decisions={data?.decisions} />
      <Card>
        <Card.Header color={COLOR.GREEN}>
          <Card.HeaderTitle>
            <HandCoins />
            {t("credit")}
          </Card.HeaderTitle>
        </Card.Header>
        <Card.Content>
          <div className="flex flex-row gap-2 text-lg justify-between overflow-x-auto">
            <InfoItem
              value={remainingCreditLimit ?? "N/A"}
              label={t("remainingCreditLimit")}
            />
            <InfoItem
              value={existingCreditLimit ?? "N/A"}
              label={t("existingCreditLimit")}
            />
            {operatorInput ? (
              <InfoItem
                value={operatorInput}
                label={t("sellersRecommendedCredit")}
              />
            ) : (
              <InfoItem
                value={desiredCredit ?? "N/A"}
                label={t("desiredCredit")}
              />
            )}
            <InfoItem
              value={approvedCredit ?? "N/A"}
              label={t("approvedCredit")}
            />
            <InfoItem
              value={maxApprovedCredit ?? "N/A"}
              label={t("maxApprovedCredit")}
            />
            <InfoItem
              value={dunAndBradstreetMaxRecommendedCredit ?? "N/A"}
              label={t("dunAndBradstreetMaxRecommendedCredit")}
            />
          </div>
        </Card.Content>
      </Card>
      <div className="mx-auto grid w-full grid-cols-2 gap-x-10 gap-y-5">
        <Card>
          <Card.Header color={COLOR.BLUE}>
            <Card.HeaderTitle>
              <Factory />
              {t("companyApplicant")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content horizontalPadding={false} className="px-5">
            <CustomTable tableData={overviewData.companyData} />
          </Card.Content>
        </Card>
        <Card>
          <Card.Header color={COLOR.BLUE}>
            <Card.HeaderTitle>
              <User />
              {t("representative")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content horizontalPadding={false} className="px-5">
            <CustomTable tableData={overviewData.representativeData} />
          </Card.Content>
        </Card>
        <Card>
          <Card.Header color={COLOR.BLUE}>
            <Card.HeaderTitle>
              <Handshake />
              {t("sellerInformation")}
            </Card.HeaderTitle>
          </Card.Header>
          <Card.Content horizontalPadding={false} className="px-5">
            <CustomTable tableData={overviewData.sellerInfoData} />
          </Card.Content>
        </Card>
      </div>
      <Card>
        <Card.Header color={COLOR.YELLOW}>
          <Card.HeaderTitle>
            <Thermometer />
            {t("risk")}
          </Card.HeaderTitle>
        </Card.Header>
        <Card.Content horizontalPadding={false} className="px-5 space-y-2">
          <div className="flex flex-col items-center justify-center">
            {overviewData.creditInfo.asValue.value && (
              <HalfMoonChart
                label={overviewData.creditInfo.asValue.value}
                credit={overviewData.creditInfo.asValue.value}
              />
            )}
            <Table>
              <TableBody>
                <TableRow className="border-0">
                  <TableCellTitle className="py-2 font-medium text-gray-700">
                    {t(overviewData.creditInfo.asPercentage.key)}
                  </TableCellTitle>
                  <TableCell className="text-right text-gray-600">
                    {overviewData.creditInfo.asPercentage.value
                      ? `${parseFloat(overviewData.creditInfo.asPercentage.value).toFixed(2)} %`
                      : "N/A"}
                  </TableCell>
                </TableRow>
                <TableRow className="border-0">
                  <TableCellTitle className="py-2 font-medium text-gray-700">
                    {`${t(overviewData.creditInfo.asValue.key)}- ${overviewData.creditInfo.asValue.year ?? t("yearNotAvailable")}`}
                  </TableCellTitle>
                  <TableCell className="text-right text-gray-600">
                    {overviewData.creditInfo.asValue.value ?? "N/A"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <Collapsible className="text-sea-sea">
            <Collapsible.Title title={t(overviewData.historicalData?.key)}>
              <CircleIcon number={overviewData.historicalData?.value?.length} />
            </Collapsible.Title>
            <Collapsible.Content>
              <CustomTable tableData={overviewData.historicalData?.value} />
            </Collapsible.Content>
          </Collapsible>
        </Card.Content>
      </Card>
      <Card>
        <Card.Header color={COLOR.BLUE}>
          <Card.HeaderTitle>
            <BookOpenText />
            {t("companyInformation")}
          </Card.HeaderTitle>
        </Card.Header>
        <Card.Content horizontalPadding={false} className="px-5">
          <CustomTable tableData={overviewData.companyInformationData} />
        </Card.Content>
      </Card>
    </div>
  );
}

function InfoItem({ value, label }: { value: string; label: string }) {
  return (
    <div className="p-4 flex flex-col items-center justify-start">
      <div className="font-bold">{value}</div>
      <div className="text-xs">{label}</div>
    </div>
  );
}
