import { PrimaryButton, Text } from "@stacc/flow-ui-components";

interface SearchFormProps {
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onMerchantSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  searchValue: string;
  searchValueMerchant: string;
}

export function SearchForm({
  onSearchChange,
  onMerchantSearchChange,
  onFormSubmit,
  searchValue,
  searchValueMerchant
}: SearchFormProps) {
  return (
    <form className="flex flex-col gap-8" onSubmit={onFormSubmit}>
      <div className="flex flex-row gap-4">
        <Text
          label="PSP Payment ID"
          name="pspPaymentId"
          onChange={onSearchChange}
          className="h-10 w-full"
          value={searchValue}
        />
        <Text
          label="Merchant Transaction Reference"
          name="MerchantTransactionRef"
          onChange={onMerchantSearchChange}
          className="h-10 w-full"
          value={searchValueMerchant}
        />
      </div>
      <div className="">
        <PrimaryButton type="submit" size="large">
          Search
        </PrimaryButton>
      </div>
    </form>
  );
}
