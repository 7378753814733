import parsePhoneNumber from "libphonenumber-js";
import { TextMasker } from "../components/TextMasker";
import { Country, COUNTRY, COUNTRY_CODE } from "../types/creditProcess/enums";
import { CreditProcessState } from "../types/creditProcess/state";
import { B2BResponseModel } from "../types/d&b/b2b";
import { CompanyCode } from "../types/d&b/b2b/companyInformation";
import { Score } from "../types/d&b/b2b/risk";
import {
  CreditInfo,
  HistoricalData,
  OverviewDataIndividual,
  TableRowDataProps
} from "../types/normalizedData";
import {
  calculateYearDifference,
  dateValuesToFormattedDate,
  formatCurrency
} from "./helpers";

export function getOverviewHelper(
  data: CreditProcessState,
  country: Country | undefined
) {
  const session = data?.session;
  const companyInformation =
    data?.raw?.dunAndBradstreet?.companyRiskAndCreditData?.companyInformation;
  const representative = data?.applicant?.mainPersonApplicant;
  const companyData: TableRowDataProps[] = [
    {
      key: "name",
      value: companyInformation?.companyName?.registeredName?.name
        ? companyInformation?.companyName?.registeredName?.name
        : data?.applicant.companyApplicant?.companyName
    },
    {
      key: "organizationNumber",
      value: companyInformation?.identifiers?.registrationNumber
        ? companyInformation?.identifiers?.registrationNumber
        : data?.applicant?.companyApplicant?.companyRegistrationNumber
    },
    {
      key: "shareCapital",
      value: formatCurrency(
        companyInformation?.capital?.shareCapital?.amount?.amount,
        companyInformation?.capital?.shareCapital?.amount?.currency
      )
    }
  ];
  const phoneNumber =
    representative?.mobilePhoneNumber?.normalizedInternationalPhoneNumber;
  const representativeData: TableRowDataProps[] = [
    {
      key: "name",
      value: TextMasker({
        text:
          (data?.applicant?.mainPersonApplicant?.firstName ||
            data?.applicant?.mainPersonApplicant?.lastName) &&
          `${data?.applicant?.mainPersonApplicant?.firstName} ${data?.applicant?.mainPersonApplicant?.lastName}`
      })
    },
    {
      key: "identity",
      value: TextMasker({
        text: representative?.identity
      })
    },
    {
      key: "phone",
      value: TextMasker({
        text: phoneNumber
          ? parsePhoneNumber(phoneNumber)?.formatInternational()
          : undefined
      })
    },
    {
      key: "email",
      value: TextMasker({
        text: representative?.email,
        direction: "left"
      })
    }
  ];
  const sellerInfoData: TableRowDataProps[] = [
    { key: "seller-id", value: session?.salesPerson?.sellerId ?? "" },
    { key: "source-system", value: session?.sourceSystem ?? "" },
    { key: "store-id", value: session?.storeId ?? "" }
  ];
  const decisionData = {
    approvedCredit: formatCurrency(
      data?.application?.approvedCredit?.value,
      data?.application?.approvedCredit?.currency
    ),
    desiredCredit: formatCurrency(
      data?.application?.desiredCredit?.value,
      data?.application?.desiredCredit?.currency
    ),
    operatorInput: formatCurrency(
      data?.application?.operatorInput?.recommendedAmount?.value,
      data?.application?.operatorInput?.recommendedAmount?.currency
    ),
    maxApprovedCredit: formatCurrency(
      data?.application?.maxApprovedCredit?.value,
      data?.application?.maxApprovedCredit?.currency
    ),
    existingCreditLimit: formatCurrency(
      data?.applicant?.companyApplicant?.existingCreditLimit?.value,
      data?.applicant?.companyApplicant?.existingCreditLimit?.currency
    ),
    remainingCreditLimit: formatCurrency(
      data?.applicant?.companyApplicant?.remainingCreditLimit?.value,
      data?.applicant?.companyApplicant?.remainingCreditLimit?.currency
    ),
    dunAndBradstreetMaxRecommendedCredit: formatCurrency(
      data?.raw?.dunAndBradstreet?.companyRiskAndCreditData?.risk
        ?.ratingCreditLimits?.currentRatingCreditLimit?.amount?.amount,
      data?.raw?.dunAndBradstreet?.companyRiskAndCreditData?.risk
        ?.ratingCreditLimits?.currentRatingCreditLimit?.amount?.currency
    )
  };

  let overviewData = null;
  switch (country) {
    case COUNTRY.SWEDEN:
      overviewData = getOverviewDataSweden(data);
      break;
    case COUNTRY.NORWAY:
      overviewData = getOverviewDataNorway(data);
      break;
    case COUNTRY.FINLAND:
      overviewData = getOverviewDataFinland(data);
      break;
    case COUNTRY.DENMARK:
      overviewData = getOverviewDataDenmark(data);
      break;
    default:
      return undefined;
  }
  return {
    ...overviewData,
    representativeData,
    companyData,
    sellerInfoData,
    decisionData
  };
}

function getOverviewDataNorway(
  data: CreditProcessState
): OverviewDataIndividual {
  const { companyInformation, risk } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.NORWAY
    >) ?? {};
  const { historicalScores, currentScore } = risk?.failureScores ?? {};

  const creditInfo = getCreditInfo(currentScore);
  const historicalData = getHistoricalData(historicalScores, currentScore);

  let establishedDate =
    companyInformation?.registrationInformation?.establishmentDate?.year ||
    companyInformation?.registrationInformation?.foundationDate?.year ||
    0;
  if (
    establishedDate === 0 &&
    companyInformation?.legalForm?.code === CompanyCode.SOLE_PROPRIETORSHIP
  ) {
    establishedDate =
      companyInformation?.registrationInformation?.registrationDate?.year || 0;
  }

  const companyInformationData: TableRowDataProps[] = [
    {
      key: "registeredName",
      value: companyInformation?.companyName?.registeredName?.name
    },
    {
      key: "industryCode",
      value:
        companyInformation?.industryCodeSet?.sni2007IndustryCodes
          ?.primaryIndustryCode?.code,
      comment:
        companyInformation?.industryCodeSet?.sni2007IndustryCodes
          ?.primaryIndustryCode?.description
    },
    {
      key: "legalForm",
      value: companyInformation?.legalForm?.localCode,
      comment: companyInformation?.legalForm?.description
    },
    {
      key: "vatStatus",
      value: companyInformation?.status?.value,
      comment: companyInformation?.status?.additionalInformation
        ?.registeredVATByMVR
        ? "Registered VAT"
        : "Not registered VAT",
      warning: companyInformation?.status?.additionalInformation?.creditBlocked
        ? "Credit blocked"
        : undefined
    },
    {
      key: "establishedDate",
      value: establishedDate,
      comment: `Registered date: ${dateValuesToFormattedDate(
        companyInformation?.registrationInformation?.registrationDate?.day,
        companyInformation?.registrationInformation?.registrationDate?.month,
        companyInformation?.registrationInformation?.registrationDate?.year
      )}`
    },
    {
      key: "numberOfEmployees",
      value: companyInformation?.generalCompanyData?.employeeCount?.toString()
    },
    {
      key: "companyAge",
      value: calculateYearDifference(establishedDate)
    }
  ];

  return {
    companyInformationData,
    historicalData,
    creditInfo
  };
}

function getOverviewDataSweden(
  data: CreditProcessState
): OverviewDataIndividual {
  const { risk, companyInformation } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.SWEDEN
    >) ?? {};
  const { historicalScores, currentScore } = risk?.failureScores ?? {};
  const creditInfo = getCreditInfo(currentScore);
  const historicalData = getHistoricalData(historicalScores, currentScore);

  const companyInformationData: TableRowDataProps[] = [
    {
      key: "registeredName",
      value: companyInformation?.companyName?.registeredName?.name
    },
    {
      key: "industryCode",
      value:
        companyInformation?.industryCodeSet?.sni2007IndustryCodes
          ?.primaryIndustryCode?.code,
      comment:
        companyInformation?.industryCodeSet?.sni2007IndustryCodes
          ?.primaryIndustryCode?.description
    },
    {
      key: "legalForm",
      value: companyInformation?.legalForm?.localCode,
      comment: companyInformation?.legalForm?.description
    },
    {
      key: "vatStatus",
      value: companyInformation?.status?.value,
      comment: companyInformation?.generalCompanyData?.registeredInVatList
        ? "Registered VAT"
        : "Not registered VAT",
      warning: undefined
    },
    {
      key: "establishedDate",
      value: dateValuesToFormattedDate(
        companyInformation?.registrationInformation?.foundationDate?.day,
        companyInformation?.registrationInformation?.foundationDate?.month,
        companyInformation?.registrationInformation?.foundationDate?.year
      )
    },
    {
      key: "numberOfEmployees",
      value: companyInformation?.generalCompanyData?.employeeCount?.toString()
    },
    {
      key: "companyAge",
      value: calculateYearDifference(
        companyInformation?.registrationInformation?.foundationDate?.year
      )
    }
  ];

  return {
    companyInformationData,
    historicalData,
    creditInfo
  };
}

function getOverviewDataFinland(
  data: CreditProcessState
): OverviewDataIndividual {
  const { risk, companyInformation } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.FINLAND
    >) ?? {};
  const { historicalCreditRatings, currentCreditRating } =
    risk?.creditRatings ?? {};
  const { currentScore } = risk?.failureScores ?? {};
  const { year: currentCreditRatingYear } =
    currentCreditRating?.annualReportDate ?? {};

  const currentCreditRatingInfo = currentCreditRatingYear
    ? {
        key: currentCreditRatingYear ?? "",
        value: currentCreditRating?.code,
        comment: currentCreditRating?.description
      }
    : null;

  let historicalData: TableRowDataProps[] =
    historicalCreditRatings?.reduce((acc, item) => {
      let year = item?.annualReportDate?.year;
      if (!year) {
        year = item?.date?.year;
      }
      if (year) {
        acc.push({
          key: year,
          value: item?.code,
          comment: item?.description
        });
      }
      return acc;
    }, [] as TableRowDataProps[]) ?? [];

  historicalData.sort((a, b) => Number(b.key!) - Number(a.key!));

  if (currentCreditRatingInfo) {
    historicalData = [currentCreditRatingInfo, ...historicalData];
  }
  const creditInfo: CreditInfo = {
    asPercentage: {
      key: "riskPercentage",
      value: currentScore?.riskPercentage,
      year: currentScore?.date?.year
    },
    asValue: {
      key: "creditRating",
      value: currentCreditRating?.code,
      year: currentCreditRating?.date?.year
    }
  };

  const companyInformationData: TableRowDataProps[] = [
    {
      key: "registeredName",
      value: companyInformation?.companyName?.registeredName?.name
    },
    {
      key: "industryCode",
      value:
        companyInformation?.industryCodeSet?.naceCodes?.primaryNaceCode?.code,
      comment:
        companyInformation?.industryCodeSet?.naceCodes?.primaryNaceCode
          ?.description
    },
    {
      key: "legalForm",
      value: companyInformation?.legalForm?.code,
      comment: companyInformation?.legalForm?.description
    },
    {
      key: "vatStatus",
      value: companyInformation?.status?.value,
      comment: companyInformation?.generalCompanyData?.registeredInVatList
        ? "Registered VAT"
        : "Not registered VAT",
      warning: undefined
    },
    {
      key: "establishedDate",
      value: dateValuesToFormattedDate(
        companyInformation?.registrationInformation?.registrationDate?.day,
        companyInformation?.registrationInformation?.registrationDate?.month,
        companyInformation?.registrationInformation?.registrationDate?.year
      )
    },
    {
      key: "numberOfEmployees",
      value: companyInformation?.generalCompanyData?.employeeRange
    },
    {
      key: "companyAge",
      value: calculateYearDifference(
        companyInformation?.registrationInformation?.registrationDate?.year
      )
    }
  ];

  return {
    companyInformationData,
    historicalData: { key: "historicalCreditRatings", value: historicalData },
    creditInfo
  };
}

function getOverviewDataDenmark(
  data: CreditProcessState
): OverviewDataIndividual {
  const { risk, companyInformation } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.DENMARK
    >) ?? {};
  const { historicalScores, currentScore } = risk?.failureScores ?? {};
  const creditInfo = getCreditInfo(currentScore);
  const historicalData = getHistoricalData(historicalScores, currentScore);

  const companyInformationData: TableRowDataProps[] = [
    {
      key: "registeredName",
      value: companyInformation?.companyName?.registeredName?.name
    },
    {
      key: "industryCode",
      value:
        companyInformation?.industryCodeSet?.naceCodes?.primaryNaceCode?.code,
      comment:
        companyInformation?.industryCodeSet?.naceCodes?.primaryNaceCode
          ?.description
    },
    {
      key: "legalForm",
      value: companyInformation?.legalForm?.current?.code,
      comment: companyInformation?.legalForm?.current?.description
    },
    {
      key: "vatStatus",
      value: companyInformation?.status?.value,
      comment: companyInformation?.identifiers?.vatNumber
        ? "Registered VAT"
        : "Not registered VAT",
      warning: undefined
    },
    {
      key: "establishedDate",
      value: dateValuesToFormattedDate(
        companyInformation?.registrationInformation?.foundationDate?.day,
        companyInformation?.registrationInformation?.foundationDate?.month,
        companyInformation?.registrationInformation?.foundationDate?.year
      )
    },
    {
      key: "numberOfEmployees",
      value: companyInformation?.generalCompanyData?.employeeCount?.toString()
    },
    {
      key: "companyAge",
      value: calculateYearDifference(
        companyInformation?.registrationInformation?.foundationDate?.year
      )
    }
  ];

  return {
    companyInformationData,
    historicalData,
    creditInfo
  };
}

function getCreditInfo(currentScore: Score): CreditInfo {
  const creditInfo: CreditInfo = {
    asPercentage: {
      key: "riskPercentage",
      value: currentScore?.riskPercentage,
      year: currentScore?.date?.year
    },
    asValue: {
      key: "creditScore",
      value: currentScore?.scoreValue,
      year: currentScore?.date?.year
    }
  };

  return creditInfo;
}

function getHistoricalData(
  historicalScores: Score[],
  currentScore: Score,
  label: string = "historicalCreditScores"
): HistoricalData {
  const { year: currentCreditInfoYear } = currentScore?.date ?? {};

  const currentCreditScoreInfo = currentCreditInfoYear
    ? {
        key: currentCreditInfoYear,
        value: currentScore?.scoreValue
      }
    : null;

  let historicalData: TableRowDataProps[] =
    historicalScores?.reduce((acc, item) => {
      const year = item?.date?.year;
      if (year) {
        acc.push({
          key: year,
          value: item?.scoreValue
        });
      }
      return acc;
    }, [] as TableRowDataProps[]) ?? [];

  historicalData.sort((a, b) => Number(b.key!) - Number(a.key!));

  if (currentCreditScoreInfo) {
    historicalData = [currentCreditScoreInfo, ...historicalData];
  }
  return { key: label, value: historicalData };
}
