import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TaskProps } from "../../types/taskProps";
import {
  getModificationsByMerchantTransactionRef,
  getModificationsByPaymentId
} from "../../utils/modifications";
import { EventsCard } from "./EventsCard";
import { ModificationsCard } from "./ModificationsCard";
import { OverviewCard } from "./OverviewCard";
import { SearchForm } from "./SearchForm";

export function Storage(props: TaskProps) {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [searchMerchant, setSearchMerchant] = useState("");
  const [flowPspPaymentId, setFlowPspPaymentId] = useState("");
  const [flowIdMerchant, setFlowIdMerchant] = useState("");

  const pspPaymentId =
    new URLSearchParams(window.location.search).get("pspPaymentId") || "";

  const merchantTransactionReference =
    new URLSearchParams(window.location.search).get(
      "merchantTransactionReference"
    ) || "";

  useEffect(() => {
    if (pspPaymentId) {
      setSearch(pspPaymentId);
      setFlowPspPaymentId(pspPaymentId);
      setFlowIdMerchant("");
    } else if (merchantTransactionReference) {
      setSearchMerchant(merchantTransactionReference);
      setFlowIdMerchant(merchantTransactionReference);
      setFlowPspPaymentId("");
    }
  }, [pspPaymentId, merchantTransactionReference]);

  const { data: pspPaymentIdKey } = useQuery({
    queryKey: ["purchasePSP", flowPspPaymentId],
    queryFn: async () => {
      if (flowPspPaymentId) {
        return getModificationsByPaymentId(flowPspPaymentId);
      }
      return null;
    },
    enabled: !!flowPspPaymentId,
    refetchInterval: 10000
  });

  const { data: merchantTransactionReferenceKey } = useQuery({
    queryKey: ["purchaseMerchant", flowIdMerchant],
    queryFn: async () => {
      if (flowIdMerchant) {
        const merchantData =
          await getModificationsByMerchantTransactionRef(flowIdMerchant);
        return Array.isArray(merchantData) ? merchantData[0] : null;
      }
      return null;
    },
    enabled: !!flowIdMerchant,
    refetchInterval: 10000
  });

  const purchase = pspPaymentIdKey || merchantTransactionReferenceKey;

  const allModifications = [
    ...(purchase?.capture || []),
    ...(purchase?.refunds || []),
    ...(purchase?.cancel || [])
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleMerchantInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchMerchant(e.target.value);
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (search) {
      setFlowPspPaymentId(search);
      history.replace(`/storage?pspPaymentId=${search}`);
    } else if (searchMerchant) {
      setFlowIdMerchant(searchMerchant);
      history.replace(
        `/storage?merchantTransactionReference=${searchMerchant}`
      );
    }
  };

  function NoResults() {
    return (
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-2xl font-medium text-primary-900">
          No results found
        </h1>
      </div>
    );
  }

  return (
    <main className="flex flex-col w-full min-h-screen overflow-y-scroll flex-1 gap-4 p-12 md:gap-10">
      <div className="flex flex-row justify-center">
        <div className="flex flex-col flex-grow 2xl:max-w-7xl xl:px-56 lg:px-32 justify-center gap-10 p-8 bg-primary-searchBoxGray border border-gray-200">
          <div className="flex justify-center">
            <h1 className="font-medium text-3xl text-primary-ferrari">
              Storage
            </h1>
          </div>
          <p className="flex justify-center text-sm font-bold text-primary-900">
            Search for PsP Payment ID or Merchant Transaction Reference
          </p>
          <div>
            <SearchForm
              onSearchChange={handleInputChange}
              onMerchantSearchChange={handleMerchantInputChange}
              onFormSubmit={handleFormSubmit}
              searchValue={search}
              searchValueMerchant={searchMerchant}
            />
          </div>
          {purchase ? (
            <>
              <OverviewCard overview={purchase} />
              {allModifications.length > 0 && (
                <ModificationsCard
                  modifications={allModifications}
                  overview={purchase}
                  props={props}
                />
              )}
              {purchase.events && purchase.events.length > 0 && (
                <EventsCard events={purchase.events} />
              )}
            </>
          ) : (
            <NoResults />
          )}
        </div>
      </div>
    </main>
  );
}
